import React, { useState, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ProjectListing } from 'components';
import { HeroBanner } from '../components';
import { Transition } from 'react-transition-group';

const CenterContent = styled.div`
  width: 90%;
  margin: auto;
  max-width: ${props => props.theme.breakpoints.l};
`;

const PushRight = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
`;

const SectionHeader = styled.h1`
  margin-top: 24px;
  margin-bottom: -4px;
  padding: 0;
`;

const FadeIn = styled.div`
  opacity: ${props => (props.state === 'entering' || props.state === 'entered' ? 1 : 0)};
  transform: translateY(${({ state }) => (state === 'entering' || state === 'entered' ? 0 : 100)}px);
  transition: 0.3s;
  transition-delay: ${props => props.delay}s;
`;

const Index = ({
  data: {
    work: { edges: workEdges },
    projects: { edges: projectsEdges },
  },
}) => {
  const [animation, setAnimation] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setAnimation(true);
    }, 100);
  }, []);
  return (
    <React.Fragment>
      <Transition in={animation} timeout={300}>
        { state => (
          <FadeIn className="animated" state={state}>
            <HeroBanner />
          </FadeIn>
        )}
      </Transition>
      <Transition in={animation} timeout={450}>
        { state => (
          <FadeIn className="animated" state={state} delay="0.15">
            <CenterContent>
              <SectionHeader>WORK</SectionHeader>
            </CenterContent>
            <ProjectListing projectEdges={workEdges} />
            <CenterContent>
              <PushRight><h2 style={{ display: 'inline-block' }}><Link to="/work/">MORE</Link></h2></PushRight>
            </CenterContent>
          </FadeIn>
        )}
      </Transition>
      <Transition in={animation} timeout={600}>
        { state => (
          <FadeIn className="animated" state={state} delay="0.30">
            <CenterContent>
              <SectionHeader>PROJECTS</SectionHeader>
            </CenterContent>
            <ProjectListing projectEdges={projectsEdges} />
            <CenterContent>
              <PushRight><h2 style={{ display: 'inline-block' }}><Link to="/projects/">MORE</Link></h2></PushRight>
            </CenterContent>
          </FadeIn>
        )}
      </Transition>
    </React.Fragment>
  );
};

export default Index;

Index.propTypes = {
  data: PropTypes.shape({
    work: PropTypes.shape({
      edges: PropTypes.array.isRequired,
    }),
    projects: PropTypes.shape({
      edges: PropTypes.array.isRequired,
    }),
  }).isRequired,
};

export const pageQuery = graphql`
  query IndexQuery {
    projects: allMdx(sort: { fields: [frontmatter___order], order: ASC }, filter: { fileAbsolutePath: {regex : "\/projects/"} }, limit: 3) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            service
            cover {
              childImageSharp {
                fluid(maxWidth: 400, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    work: allMdx(sort: { fields: [frontmatter___order], order: ASC }, filter: { fileAbsolutePath: {regex : "\/work/"} }, limit: 3) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            service
            cover {
              childImageSharp {
                fluid(maxWidth: 400, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
